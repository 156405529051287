<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Leave Opening Balance" slot="title" link="/helpContent/Leave Opening Balance" />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-4">
          <FormSelect
            label="Company"
            :items="companyList"
            item-name="Name" 
            item-value="id"
            v-model="filtering.companyId"
            rules="required"
            :onChange="getOpeningBalData"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-md-4">
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </div>
      </FormRow>
    </ValidationObserver>
    <FormRow>
      <div class="col-sm-2">
        <strong>Emp Code</strong>
      </div>
      <div class="col-sm-2">
        <strong>Employee Name</strong>
      </div>
      <div class="col-sm-2">
        <strong>EL</strong>
      </div>
      <div class="col-sm-2">
        <strong>EL Provisional</strong>
      </div>
      <div class="col-sm-2">
        <strong>CL</strong>
      </div>
      <div class="col-sm-2">
        <strong>CL Provisional</strong>
      </div>
    </FormRow>
    <div v-if="listData.length">
      <div v-for="(item, i) in listData" :key="i">
          <FormRow>
              <div class="col-sm-2">
                <div v-html="listData[i].EmpCode"></div>
              </div>
              <div class="col-sm-2">
                <div v-html="listData[i].Name"></div>
              </div>
              <div class="col-sm-2">
                  <InputText v-model="listData[i].EL" />
              </div>
              <div class="col-sm-2">
                <InputText v-model="listData[i].ELProvisional" />
              </div>
              <div class="col-sm-2">
                <InputText v-model="listData[i].CL" />
              </div>
              <div class="col-sm-2">
                <InputText v-model="listData[i].CLProvisional" />
              </div>
          </FormRow>
      </div>
    </div>
    <div v-else>
      <div :style="{textAlign: 'center'}">
        No Data present
      </div>
    </div>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { getAllCompanyList, getStaffToEnterOpeningBalance, insertLeaveOpBalance } from "../api";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      filtering: {
        companyId: null,
      },
      companyList: [],
      listData: [],
    };
  },
  created() {
    this.getAllCompany();
  },
  methods: {
    getAllCompany() {
      getAllCompanyList().then((res) => {
        this.companyList = res.data;
      });
    },
    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          this.showMessage.isVisible=false;
          const openingBalanceData = []
          for(var i = 0; i < this.listData.length; i++){
            openingBalanceData.push({
              staff_id: this.listData[i].id,
              ELBalance: this.listData[i].EL,
              CLBalance: this.listData[i].CL,
              ELBalanceProvisional: this.listData[i].ELProvisional,
              CLBalanceProvisional: this.listData[i].CLProvisional,
              Createdby: this.userData.user_id
            })
          }
          this.showLoader();
          const data = {
            openingBalanceData: JSON.stringify(openingBalanceData),
          };
          insertLeaveOpBalance(data)
            .then(this.handleSuccessResponse)
            .catch(this.handleError);
        }
      });
    },
    handleSuccessResponse(response) {
      this.hideLoader();
      this.showMessage.isVisible = true;
      this.showMessage.message = response.data[0].message;
    },
    getDataByFilter(ele) {
      setTimeout(() => {
        this.$refs.validator.validate().then((result) => {
          if (result) {
            const filters = {};
            if (ele == undefined) {
                this.filtering.companyId=0;
            } else{
                this.filtering.companyId=ele;
            }
            filters.companyId = this.filtering.companyId;
            const data = {
              filterjson: {
                filter: [filters]
              },
            };
            getStaffToEnterOpeningBalance(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }
        });
      }, 50);
    },
    handleResponse(response) {
      this.hideLoader();
      this.listData = response.data;
    },
    getOpeningBalData(ele){
        this.getDataByFilter(ele);
    },
  },
};
</script>